import React from "react"
import Head from "../components/head"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Text from "../components/text"

export default function NotFound() {
  return (
    <Layout>
        <Head title="Seite nicht gefunden" />
        <Hero
            title="Seite nicht gefunden"
            description=""
            image="" />
        <Text content="<p>Diese Seite wurde leider nicht gefunden. Besuchen Sie gern meine <a href='/'>Startseite</a>, um mehr über mich und meine Arbeit zu erfahren.</p>" />
    </Layout>
  )
}