import React, { useState, useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import MenuItem from "../components/menuItem"
import MobileMenu from "../components/mobileMenu"
import Overlay from "../components/overlay"
import * as headerStyles from "./header.module.scss"

export default function Header() {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
            allFile(filter: {extension: {eq: "md"}, name: {eq: "home"}}) {
                edges {
                    node {
                        name
                        childMarkdownRemark {
                            frontmatter {
                                title
                                services {
                                    title
                                }
                                about {
                                    title
                                }
                                news {
                                    title
                                }
                                contact {
                                    title
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const home = data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

    const [isExpanded, setExpanded] = useState(false);

    function toggleMenu() {
        isExpanded ? setExpanded(false) : setExpanded(true);
    }

    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        function handleScroll() {
            setScroll(window.scrollY > 100);
        }

        window.addEventListener("scroll", handleScroll);

        handleScroll();

        return () => window.removeEventListener("resize", handleScroll);
    })

    return (
        <div>
            <header className={headerStyles.container}>
                <nav className={headerStyles.content}>
                    <Link to="/" className={headerStyles.logo}>{data.site.siteMetadata.title}</Link>
                    <button onClick={toggleMenu} className={headerStyles.button} aria-label="Menü öffnen">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 17" width="24" height="17" preserveAspectRatio="xMidYMid meet">
                            <g>
                                <g transform="matrix(1,0,0,1,-128.5,243.5)" opacity="1">
                                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                        <path strokeLinecap="round" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(255,255,255)" strokeOpacity="1" strokeWidth="2" d=" M130.2480010986328,-227.78700256347656 C130.2480010986328,-227.78700256347656 146.87100219726562,-227.78700256347656 148.45399475097656,-227.78700256347656"></path>
                                    </g>
                                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                        <path strokeLinecap="round" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(255,255,255)" strokeOpacity="1" strokeWidth="2" d=" M130.2480010986328,-234.8459930419922 C130.2480010986328,-234.8459930419922 142.98199462890625,-234.8459930419922 144.19400024414062,-234.8459930419922"></path>
                                    </g>
                                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                        <path strokeLinecap="round" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(255,255,255)" strokeOpacity="1" strokeWidth="2" d=" M130.2480010986328,-241.906005859375 C130.2480010986328,-241.906005859375 148.72000122070312,-241.906005859375 150.47999572753906,-241.906005859375"></path>
                                    </g>
                                    </g>
                                    <g transform="matrix(1,0,0,1,-128.5,243.5)" opacity="1">
                                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                        <path strokeLinecap="round" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(255,255,255)" strokeOpacity="1" strokeWidth="2" d="M0 0"></path>
                                    </g>
                                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                        <path strokeLinecap="round" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(255,255,255)" strokeOpacity="1" strokeWidth="2" d="M0 0"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                    <ul className={headerStyles.list}>
                        <MenuItem title={home.title} to={home.title.replace(/\s+/g, '-').toLowerCase()} class={headerStyles.item} activeClass={headerStyles.active} />
                        <MenuItem title={home.services.title} to={home.services.title.replace(/\s+/g, '-').toLowerCase()}  class={headerStyles.item} activeClass={headerStyles.active} />
                        <MenuItem title={home.about.title} to={home.about.title.replace(/\s+/g, '-').toLowerCase()}  class={headerStyles.item} activeClass={headerStyles.active} />
                        <MenuItem title={home.news.title} to={home.news.title.replace(/\s+/g, '-').toLowerCase()}  class={headerStyles.item} activeClass={headerStyles.active} />
                        <MenuItem title={home.contact.title} to={home.contact.title.replace(/\s+/g, '-').toLowerCase()}  class={headerStyles.item} activeClass={headerStyles.active} />
                    </ul>
                    <ul className={`${[headerStyles.list, headerStyles.lang].join(" ")}`}>
                        <li className={headerStyles.item}>
                            <Link to="/">DE</Link>
                        </li>
                        <li className={headerStyles.item}>
                        <Link to="/en">EN</Link>
                        </li>
                    </ul>
                </nav>
            </header>
            <header className={(scroll ? `${[headerStyles.container, headerStyles.sticky].join(" ")}` : `${[headerStyles.container, headerStyles.sticky, headerStyles.hidden].join(" ")}`)}>
                <nav className={headerStyles.content}>
                    <Link to="/" className={headerStyles.logo}>{data.site.siteMetadata.title}</Link>
                    <button onClick={toggleMenu} className={headerStyles.button} aria-label="Menü öffnen">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 17" width="24" height="17" preserveAspectRatio="xMidYMid meet">
                            <g>
                                <g transform="matrix(1,0,0,1,-128.5,243.5)" opacity="1">
                                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                        <path strokeLinecap="round" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(255,255,255)" strokeOpacity="1" strokeWidth="2" d=" M130.2480010986328,-227.78700256347656 C130.2480010986328,-227.78700256347656 146.87100219726562,-227.78700256347656 148.45399475097656,-227.78700256347656"></path>
                                    </g>
                                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                        <path strokeLinecap="round" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(255,255,255)" strokeOpacity="1" strokeWidth="2" d=" M130.2480010986328,-234.8459930419922 C130.2480010986328,-234.8459930419922 142.98199462890625,-234.8459930419922 144.19400024414062,-234.8459930419922"></path>
                                    </g>
                                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                        <path strokeLinecap="round" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(255,255,255)" strokeOpacity="1" strokeWidth="2" d=" M130.2480010986328,-241.906005859375 C130.2480010986328,-241.906005859375 148.72000122070312,-241.906005859375 150.47999572753906,-241.906005859375"></path>
                                    </g>
                                    </g>
                                    <g transform="matrix(1,0,0,1,-128.5,243.5)" opacity="1">
                                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                        <path strokeLinecap="round" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(255,255,255)" strokeOpacity="1" strokeWidth="2" d="M0 0"></path>
                                    </g>
                                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                        <path strokeLinecap="round" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(255,255,255)" strokeOpacity="1" strokeWidth="2" d="M0 0"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                    <ul className={headerStyles.list}>
                        <MenuItem title={home.title} to={home.title.replace(/\s+/g, '-').toLowerCase()} class={headerStyles.item} activeClass={headerStyles.active} />
                        <MenuItem title={home.services.title} to={home.services.title.replace(/\s+/g, '-').toLowerCase()}  class={headerStyles.item} activeClass={headerStyles.active} />
                        <MenuItem title={home.about.title} to={home.about.title.replace(/\s+/g, '-').toLowerCase()}  class={headerStyles.item} activeClass={headerStyles.active} />
                        <MenuItem title={home.news.title} to={home.news.title.replace(/\s+/g, '-').toLowerCase()}  class={headerStyles.item} activeClass={headerStyles.active} />
                        <MenuItem title={home.contact.title} to={home.contact.title.replace(/\s+/g, '-').toLowerCase()} class={headerStyles.item} activeClass={headerStyles.active} />
                    </ul>
                    <ul className={`${[headerStyles.list, headerStyles.lang].join(" ")}`}>
                        <li class={headerStyles.item}>
                            <Link to="/">DE</Link>
                        </li>
                        <li class={headerStyles.item}>
                        <Link to="/en">EN</Link>
                        </li>
                    </ul>
                </nav>
            </header>
            <Overlay active={isExpanded} />
            <MobileMenu active={isExpanded} onClick={toggleMenu} />
        </div>
    )
}